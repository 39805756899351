export const ARTICLE_MAGAZINE_FRAGMENT = `
  fragment articleMagazineFragment on Article {
    uid
    name
    articleImages {
     path
     uid
    }
    featured
    subtitle
    authorName
    updatedTime
    publicationTime
    _isRecommendedForMe
    __typename
  }
`;
